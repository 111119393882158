/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-14 14:07:12
 * @Module: 暂无数据
 */
 <template>
  <div class="noData-box">
    <img src="@/static/homepage/no_data.png"
         alt=""
         srcset="">
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
 </script>
 <style lang='scss' scoped>
.noData-box {
  background: #fff;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>